import * as React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import { BlockContent, Blocks } from '~/components';

export default function Post({ pageContext, data }) {
  const { title, blocks } = pageContext;
  const { primaryColor, secondaryColor, bgColor } = data?.post || {};

  // set page theme
  let styles = [];
  primaryColor && styles.push(`--primary-color: ${primaryColor}`);
  secondaryColor && styles.push(`--secondary-color: ${secondaryColor}`);
  bgColor && styles.push(`--background-color: ${bgColor}`);
  styles = styles.join('; ');

  return (
    <>
      <Helmet>
        <style>{`:root {${styles}}`}</style>
      </Helmet>
      <BlockContent
        isPost
        style={{
          '--py': '14.4rem',
        }}
      >
        <h1 style={{ '--my': '3.6rem' }}>{parse(title)}</h1>
        <Blocks blocks={blocks} />
      </BlockContent>
    </>
  );
}

export const query = graphql`
  query post($id: String!) {
    post: wpPost(id: { eq: $id }) {
      primaryColor
      secondaryColor
      bgColor
      featuredImage {
        remoteFile {
          ...HeroImage
        }
        altText
      }
    }
  }
`;
